<template>
  <div class="join-notify iphone-x" v-if="list && list.length > 0">
    <div>
      <div class="info fx-row fx-center">
        <div class="avatar" v-for="(item, index) of list" :key="index"
             :style="{backgroundImage: getAvatarBg(index)}">
        </div>

        <div class="txt fx-1">Membuat grup untuk dapatkan kupon diskon</div>
        <div class="btn" @click="onShowAllGroups">
          <div>JOIN</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import formatBackgroundImgUrl from '../../../filters/formatBackgroundImgUrl'

  export default {
    props: ['list'],
    methods: {
      getAvatarBg(index) {
        let memebers = this.list && this.list[index] && this.list[index].members
        let master = memebers.filter((item) => {
          return item.is_initiator
        })[0]

        return formatBackgroundImgUrl(master && master.avatar || '')
      },
      onShowAllGroups() {
        this.$emit('all', true)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .join-notify {
    width: 100%;
    background-color: $c2;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.10);
    transition: all 500ms;

    > div {
      height: pxTo(48 + 36);
      position: relative;

      .info {
        height: pxTo(36);
        padding: 0 pxTo(6) 0 pxTo(10+8);

        .avatar {
          width: pxTo(18);
          height: pxTo(18);
          border-radius: 50%;
          background-color: $c14;
          margin-left: pxTo(-8);
          border: 1px solid $c2;
          overflow: hidden;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .txt {
          margin-left: pxTo(10);

          @extend %ff-rr;
          @include fs-l-w-c(11, 13, 400, $c10);
        }

        .btn {
          width: pxTo(54);
          height: pxTo(24);
          background-image: $bg1;
          padding: 1px;
          border-radius: pxTo(4);
          overflow: hidden;
          text-align: center;

          > div {
            width: 100%;
            height: 100%;
            border-radius: pxTo(3);
            background-color: $c2;

            @extend %ff-rb;
            @include fs-l-w-c(11, 23, 900, $c5);
          }
        }
      }
    }
  }
</style>