<template>
  <div class="fx-center popup-mask" v-if="isShow" @click="onClose">
    <div class="join-group pupup-center fx-c-center" @click.stop="">
      <div class="title">Join {{name}}</div>
      <div class="desc">Kurang <span>{{lessNum}} Orang</span>, Sisa Waktu:</div>

      <div class="count-down fx-center">
        <times type="min" :remain="remain" @over="onOver"></times>
      </div>

      <div class="members fx-row fx-center fail">
        <div class="avatar master">
          <div :style="{backgroundImage:avatarBg}"></div>
        </div>
        <div class="avatar question">
          <div></div>
        </div>
      </div>

      <!-- 加入拼团 -->
      <div class="btn" @click="onJoin">JOIN UNTUK DAPATKAN KUPON</div>
    </div>
  </div>
</template>

<script>
  import formatBackgroundImgUrl from '../filters/formatBackgroundImgUrl'
  import formatDealineDate from '../filters/formatDealineDate'
  //mixin
  import groupHandler from '../mixins/groupHandler'
  //component
  import Times from '../components/times'

  let lock = false

  export default {
    props: ['isShow', 'info'],
    components: {
      Times
    },
    mixins: [groupHandler],
    computed: {
      activityId() {
        return this.info && this.info.activity_id || ''
      },
      master() {
        let memebers = this.info && this.info.members || []
        let master = memebers.filter((item) => {
          return item.is_initiator
        })

        return master && master[0] || false
      },
      avatarBg() {
        return formatBackgroundImgUrl(this.master && this.master.avatar || '')
      },
      name() {
        return (this.master && this.master.username || 'no name')
      },
      lessNum() {
        return this.info.expected_members - this.info.current_members
      },
      remain() {
        return formatDealineDate(this.info.start_time, this.info.group_duration) - (this.info.current_time || 0)
      }
    },
    methods: {
      onJoin() {
        if (lock) {
          return
        }
        lock = true

        this.joinById(this.info.id, this.activityId).then(() => {
          lock = false
        })
      },
      onClose() {
        this.$emit('close', false)
      },
      onOver() {
        this.$emit('over')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .join-group {
    height: pxTo(308);
    padding-top: pxTo(61);

    &::before {
      content: "";
      display: block;
      width: pxTo(375);
      height: pxTo(375);
      bottom: pxTo(58);

      @extend %h-center;
      @include bg($img-base, 'img_dialog_joingroup.png');
      background-size: 100% auto;
      background-position: top center;
    }

    .title {
      text-align: center;
      height: pxTo(21);

      @extend %ellipsis;
      @extend %ff-rm;
      @include fs-l-w-c(18, 21, 500, $c9);
    }

    .desc {
      text-align: center;
      height: pxTo(14);
      margin-top: pxTo(8);

      @extend %ellipsis;
      @extend %ff-rr;
      @include fs-l-w-c(12, 14, 400, $c10);

      > span {
        @extend %ff-rb;
        @include fs-l-w-c(12, 14, 700, $c16);
      }
    }

    .count-down {
      margin-top: pxTo(22);
    }

    .members {
      margin-top: pxTo(21);

      .avatar {
        width: pxTo(48);
        height: pxTo(48);
        padding: pxTo(2);
        background: $bg1;
        position: relative;
        margin-left: pxTo(25);
        overflow: visible;

        &.master {
          margin-left: 0;
          margin-right: pxTo(25);

          &::after {
            content: "";
            display: block;
            width: pxTo(20);
            height: pxTo(20);
            position: absolute;
            bottom: 0;
            right: pxTo(-5);

            @include bg($img-base, 'ic_avatar_host.svg');
            background-size: 100% 100%;
          }

          &::before {
            content: "";
            display: block;
            width: 1px;
            height: pxTo(21);
            background: $c14;
            right: pxTo(-24);

            @extend %v-center;
          }
        }

        &.question {
          @include bg($img-base, 'ui_avatar_question.svg');
          background-size: 100% 100%;

          > div {
            display: none;
          }
        }

        > div {
          background-size: 100% 100%;
          overflow: hidden;
        }
      }
    }

    .btn {
      width: 100%;
      height: pxTo(58);
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 0 0 pxTo(16) pxTo(16);
      background: $bg1;

      @extend %ff-rb;
      @include fs-l-w-c(16, 58, 900, $c2);
    }
  }
</style>