<template>
  <div class="item fx-row fx-center">
    <div class="avatar master">
      <div :style="{backgroundImage:avatarBg}"></div>
    </div>

    <div class="info fx-1 b-b">
      <div class="name">{{name}}</div>
      <div class="desc">Kurang <span>{{lessNum}} Orang</span></div>
      <times type="min" :remain="remain" @over="onOver"></times>

      <div class="btn" @click="onJoin">
        <div>JOIN</div>
      </div>
    </div>
  </div>
</template>

<script>
  import formatBackgroundImgUrl from '../filters/formatBackgroundImgUrl'
  import formatDealineDate from '../filters/formatDealineDate'
  //mixin
  import groupHandler from '../mixins/groupHandler'
  //component
  import Times from '../components/times'

  let lock = false

  export default {
    props: ['info'],
    mixins: [groupHandler],
    components: {Times},
    computed: {
      activityId() {
        return this.info && this.info.activity_id || ''
      },
      master() {
        let memebers = this.info && this.info.members || []
        let master = memebers.filter((item) => {
          return item.is_initiator == 1
        })

        return master && master[0] || false
      },
      avatarBg() {
        return formatBackgroundImgUrl(this.master && this.master.avatar || '')
      },
      name() {
        return (this.master && this.master.username || "no name")
      },
      lessNum() {
        return this.info.expected_members - this.info.current_members
      },
      remain() {
        return formatDealineDate(this.info.start_time, this.info.group_duration) - (this.info.current_time || 0)
      }
    },
    methods: {
      onJoin() {
        if (lock) {
          return
        }
        lock = true

        this.joinById(this.info.id, this.activityId).then(() => {
          lock = false
        })
      },
      onOver() {
        this.$emit('over')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    height: pxTo(80);
    overflow: hidden;

    .avatar {
      width: pxTo(48);
      height: pxTo(48);
      padding: pxTo(2);
      background: $bg1;
      position: relative;
      margin-right: pxTo(21);
      overflow: visible;

      &.master {
        margin-left: 0;
        margin-right: pxTo(25);

        &::after {
          content: "";
          display: block;
          width: pxTo(20);
          height: pxTo(20);
          position: absolute;
          bottom: 0;
          right: pxTo(-5);

          @include bg($img-base, 'ic_avatar_host.svg');
          background-size: 100% 100%;
        }
      }
    }

    .info {
      height: pxTo(80);
      padding-top: pxTo(17);
      padding-bottom: pxTo(17);
      position: relative;

      .name {
        height: pxTo(16);

        @extend %ff-rr;
        @include fs-l-w-c(14, 16, 400, $c9);
      }

      .desc {
        height: pxTo(13);
        margin-top: pxTo(1);
        margin-bottom: pxTo(4);

        @extend %ff-rr;
        @include fs-l-w-c(11, 13, 400, $c10);

        span {
          @extend %ff-rb;
          @include fs-l-w-c(11, 13, 700, $c16);
        }
      }

      .btn {
        width: pxTo(70);
        height: pxTo(36);
        border-radius: pxTo(6);
        padding: 1px;
        background: $bg1;
        overflow: hidden;
        right: 0;
        z-index: 5;

        @extend %v-center;

        > div {
          width: 100%;
          height: 100%;
          border-radius: pxTo(5);
          text-align: center;
          overflow: hidden;
          background: $c2;

          @extend %ff-rb;
          @include fs-l-w-c(14, 34, 900, $c5);
        }
      }
    }
  }
</style>