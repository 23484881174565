<template>
  <div class="banner"
       :style="{height: category !== 36 ? '100%' : '13.5rem'}">
    <div class="coupon"
         :class="[type]"
         :style="{height: category !== 36 ? '16.8rem' : '6.8rem', padding: category !== 36 ? '0 0.8rem' : ''}">
      <div id="bannerSwiper"
           class="swiper-container">
        <div class="swiper-wrapper">
          <div class="item swiper-slide"
               v-for="(item, index) of list"
               :key="index"
               :style="{backgroundImage:getBg(item)}"></div>
        </div>
      </div>

      <!-- 每日售磬 -->
      <div class="mask"
           v-if="dailyStatus == 1"></div>
    </div>

    <div class="content">
      <!--round type="white"></round-->

      <div class="info">
        <div class="van-multi-ellipsis--l2">
          <div class="title">{{title}}</div>
        </div>
        <div class="desc">{{desc}}</div>
        <div class="tips">Terjual {{total}} Buah</div>
        <div class="price fx-row fx-v-center">
          Rp{{formatSplitMoney(info.coupon_price)}}
          <div class="origin"
               v-if="info.origin_price">Rp{{info.origin_price}}</div>
          <!-- 8.24新增展示免邮小卡片 -->
          <!-- <div class="card"
               v-if="category !== 36">Gratis Pengiriman</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatDiscountRate from '../../../filters/formatDiscountRate'
import formatBackgroundImgUrl from '../../../filters/formatBackgroundImgUrl'
import formatSplitMoney from '../../../filters/formatSplitMoney'
import Swiper, { Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Pagination, Autoplay])

/* eslint-disable-next-line */
let swiper

export default {
  props: ['info', 'dailyStatus'],
  data () {
    return {
      total: Math.random().toFixed(2).slice(-2)
    }
  },
  computed: {
    title () {
      return this.info && this.info.coupon_title || ''
    },
    desc () {
      return this.info && this.info.coupon_desc || ''
    },
    tags () {
      return this.info && this.info.tags || []
    },
    discountRate () {
      return formatDiscountRate(this.info && this.info.discount_rate || 0)
    },
    type () {
      return (this.info && this.info.discount_rate || 0) == 0 ? 'free' : 'discount'
    },
    list () {
      return this.info && this.info.goods_imgs || false
    },
    category () {
      return this.info && this.info.category
    }
  },
  methods: {
    formatSplitMoney,
    initSwiper () {
      if (this.list && this.list.length > 1) {
        swiper = new Swiper('#bannerSwiper', {
          loop: true,
          slidesPerView: 'auto',
          delay: 3000,
          autoplay: true
        })
      } else {
        swiper = null
      }
    },
    getBg (img) {
      return formatBackgroundImgUrl(img || '')
    },
    gotoShopify (url) {
      this.gotoPage('shopify', { url })
    }
  },
  mounted () {
    this.$nextTick(this.initSwiper)
  },
  beforeDestroy () {
    swiper && swiper.destroy()
    swiper = null
  }
}
</script>

<style lang="scss" scoped>
.banner {
  // height: 100%;
  background-color: $c1;
  padding: pxTo(12) 0 0 0;
  position: relative;

  .coupon {
    width: 100%;
    height: pxTo(136);
    position: relative;
    overflow: hidden;

    .mask {
      width: 100%;
      height: pxTo(136);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;

      @include bg($img-base, "banner-mask.png");
      background-size: 100% auto;
    }

    .swiper-container {
      border-radius: pxTo(10) pxTo(10) 0 0;
      overflow: hidden;
    }

    .item {
      background-size: 100% auto !important;
      background-position: top center !important;
    }

    /*&.free {
        .item0 {
          @include bg($img-base, 'ads/ad1.png?1');
        }

        .item1 {
          @include bg($img-base, 'ads/ad4.png?1');
        }

        .item2 {
          @include bg($img-base, 'ads/ad2.png?1');
        }

        .item3 {
          @include bg($img-base, 'ads/ad7.png?1');
        }

        .item4 {
          @include bg($img-base, 'ads/ad9.png?1');
        }

        .item5 {
          @include bg($img-base, 'ads/ad8.png?1');
        }

        .item6 {
          @include bg($img-base, 'ads/ad5.png?1');
        }
      }

      &.discount {
        .item0 {
          @include bg($img-base, 'ads/ad6.png?1');
        }

        .item1 {
          @include bg($img-base, 'ads/ad4.png?1');
        }

        .item2 {
          @include bg($img-base, 'ads/ad9.png?1');
        }

        .item3 {
          @include bg($img-base, 'ads/ad3.png?1');
        }

        .item4 {
          @include bg($img-base, 'ads/ad7.png?1');
        }

        .item5 {
          @include bg($img-base, 'ads/ad8.png?1');
        }

        .item6 {
          @include bg($img-base, 'ads/ad5.png?1');
        }
      }*/
  }

  .content {
    padding: 0 pxTo(16);
  }

  .info {
    height: pxTo(112);
    padding: pxTo(12) pxTo(16) pxTo(14) pxTo(16);
    position: relative;
    // box-sizing: border-box;

    @extend %ff-rm;
    @include bg($img-base, "img_ticket_bottom.svg");
    background-size: 100% auto;
    background-position: bottom center;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: pxTo(12);
      background-color: $c2;
      position: absolute;
      left: 0;
      top: 0;
    }

    .title {
      // word-break: break-all;
      // text-overflow: -o-ellipsis-lastline;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      width: pxTo(240);
      @extend %ff-rm;
      @include fs-l-w-c(16, 21, 400, $c9);
    }

    .desc {
      word-break: break-all;
      text-overflow: -o-ellipsis-lastline;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: pxTo(6);
      width: 80%;

      @include fs-l-w-c(12, 14, 400, $c10);
    }

    .tips {
      padding: 0 pxTo(6);
      height: pxTo(18);
      background-color: $c7;
      border-radius: pxTo(10) 0 0 pxTo(10);
      position: absolute;
      right: 0;
      top: pxTo(36);
      border-right: pxTo(2) solid $c8;

      @extend %no-break;
      @extend %ff-rm;
      @include fs-l-w-c(9, 18, 500, $c8);
    }

    .price {
      margin-top: pxTo(8);

      @extend %ff-rm;
      @include fs-l-w-c(20, 16, 500, $c16);

      .origin {
        text-decoration: line-through;
        margin-left: pxTo(10);

        @include fs-l-w-c(14, 10, 400, $c20);
      }

      .card {
        margin-left: pxTo(10);
        background-color: #ff8e1b;
        padding: 2px 3px;
        box-sizing: border-box;

        @include fs-l-w-c(12, 16, 400, $c15);
      }
    }
  }
}
</style>