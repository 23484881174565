<template>
  <div class="fx-center popup-mask" v-if="isShow" @click="onClose">
    <div class="join-group pupup-center fx-c-center" @click.stop="">
      <div class="title">Join</div>

      <div class="list b-t">
        <group-item v-for="item of list" :info="item" :key="item.id" over="onOver"></group-item>
      </div>
    </div>
  </div>
</template>

<script>
  import GroupItem from './groupItem'

  export default {
    props: ['isShow', 'list'],
    components: {
      GroupItem
    },
    methods: {
      onClose() {
        this.$emit('close', false)
      },
      onOver() {
        this.$emit('over')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .join-group {
    padding-top: pxTo(44);

    &::before {
      content: "";
      display: block;
      width: pxTo(286);
      height: pxTo(47);
      top: pxTo(-47);
      background: $bg6;
      border-radius: pxTo(16) pxTo(16) 0 0;

      @extend %h-center;
    }

    &::after {
      content: "";
      display: block;
      width: pxTo(58);
      height: pxTo(58);
      top: pxTo(-29);

      @extend %h-center;
      @include bg($img-base, 'ic_avatar_host.svg');
      background-size: 100% 100%;
    }

    .title {
      text-align: center;
      height: pxTo(21);

      @extend %ellipsis;
      @extend %ff-rm;
      @include fs-l-w-c(18, 21, 500, $c9);
    }

    .list {
      margin-top: pxTo(15);
      padding: 0 pxTo(16) pxTo(15) pxTo(12);
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: pxTo(52);
        background: $bg5;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 0 pxTo(16) pxTo(16);
      }
    }
  }
</style>