<template>
  <div class="item fx-row b-t">
    <div class="avatar">
      <div :style="{backgroundImage:avatarBg}"></div>
    </div>

    <div class="info fx-1">
      <div class="name">{{name}}</div>
      <!-- 进行中的拼团 -->
      <div class="groups" v-if="status == 0">
        <span>{{lessNum}} orang</span>
        lagi bisa sukses
      </div>
      <!-- 已成功的拼团 -->
      <div class="groups" v-else>Barang berhasil dibeli</div>
      <!-- 进行中的拼团 -->
      <div class="count-down" v-if="status == 0">
        <times type="min" :remain="remain" @over="onOver"></times>
      </div>
      <!-- 已成功的拼团 -->
      <div class="count-down" v-else>
        <times type="min over" :during="during" only-show="1"></times>
      </div>

      <div class="btn-rainbow" @click="onJoinGroups">
        <div>JOIN & DAPATKAN KUPON</div>
      </div>
    </div>

    <!-- 进行中的拼团 -->
    <!--div class="count-down" v-if="status == 0">
      <times type="min" :remain="remain" @over="onOver"></times>
    </div-->
    <!-- 已成功的拼团 -->
    <!--div class="count-down" v-else>
      <times type="min over" :during="during" only-show="1"></times>
    </div>
    <div class="name">{{name}}</div>
    <div class="btn" @click="onJoinGroups">
      <div>JOIN & DAPATKAN KUPON</div>
    </div>
    <div class="progress">
      <div class="per" :style="{width: per}"></div>
      <div class="info" v-if="status == 0">Kurang {{lessNum}} orang untuk berhasil membeli</div>
      <div class="info success" v-else>Kupon berhasil didapatkan</div>
    </div-->
  </div>
</template>

<script>
  import formatPer from '../../../filters/formatPer'
  import formatBackgroundImgUrl from '../../../filters/formatBackgroundImgUrl'
  import formatDealineDate from '../../../filters/formatDealineDate'
  //component
  import Times from '../../../components/times'

  export default {
    data() {
      return {
        per: '0%'
      }
    },
    props: ['info'],
    components: {
      Times
    },
    watch: {
      info(val) {
        this.per = getPer(val)
      }
    },
    computed: {
      status() {
        return this.info && this.info.status || 0
      },
      master() {
        let memebers = this.info && this.info.members || []
        let master = memebers.filter((item) => {
          return item.is_initiator == 1
        })

        return master && master[0] || false
      },
      avatarBg() {
        return formatBackgroundImgUrl(this.master && this.master.avatar || '')
      },
      name() {
        return (this.master && this.master.username || 'no name')
      },
      lessNum() {
        return this.info.expected_members - this.info.current_members
      },
      remain() {
        return formatDealineDate(this.info.start_time, this.info.group_duration) - (this.info.current_time || 0)
      },
      during() {
        return (this.info.complete_time || 0) - (this.info.start_time || 0)
      }
    },
    methods: {
      onJoinGroups() {
        //显示全部可参与组
        if (this.status == 1) {
          this.$emit('all', true)
        }
        //显示指定组
        else {
          this.$emit('one', true)
        }
      },
      onOver() {
        this.$emit('over')
      }
    },
    created() {
      setTimeout(() => {
        this.per = getPer(this.info)
      }, 300)
    }
  }

  function getPer(val) {
    return val && formatPer(val.current_members, val.expected_members)
  }
</script>

<style lang="scss" scoped>
  .item {
    height: pxTo(85);
    padding: pxTo(16) pxTo(16) 0 pxTo(16);

    .avatar {
      width: pxTo(36);
      height: pxTo(36);
      border-radius: 100%;
      background-image: $bg1;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin: 0 auto;
      padding: pxTo(2);
      overflow: visible;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: pxTo(14);
        height: pxTo(14);
        position: absolute;
        bottom: 0;
        right: 0;

        @include bg($img-base, 'ic_avatar_host.svg');
      }

      > div {
        background-size: 100% 100%;
        overflow: hidden;
      }
    }

    .info {
      margin-left: pxTo(8);
      position: relative;

      .name {
        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c9);
      }
    }

    .groups {
      height: pxTo(16);
      padding: 0 pxTo(6);
      background-color: $c15;
      border-radius: pxTo(10);
      display: inline-block;
      margin-top: pxTo(4);

      > span {
        color: $c5;
      }

      @extend %ff-rr;
      @include fs-l-w-c(9, 16, 400, $c10);
    }

    .count-down {
      margin-top: pxTo(4);
    }

    .btn-rainbow {
      position: absolute;
      right: 0;
      bottom: pxTo(16);

      > div {
        height: pxTo(26);
        padding: 0 pxTo(12);

        @include fs-l-w-c(10, 26, 900, $c5);
      }
    }
  }

  /*.item {
    padding-top: pxTo(34);
    position: relative;

    .count-down {
      position: absolute;
      right: pxTo(10);
      top: pxTo(6);
    }

    .avatar {
      width: pxTo(64);
      height: pxTo(64);
      border-radius: 100%;
      background-image: $bg1;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin: 0 auto;
      padding: pxTo(3);
      overflow: visible;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: pxTo(18);
        height: pxTo(18);
        position: absolute;
        bottom: 0;
        right: 0;

        @include bg($img-base, 'ic_avatar_host.svg');
      }

      > div {
        background-size: 100% 100%;
        overflow: hidden;
      }
    }

    .name {
      max-width: pxTo(150);
      text-align: center;
      margin: pxTo(9) auto 0 auto;

      @extend %ellipsis;
      @extend %ff-rr;
      @include fs-l-w-c(12, 14, 400, $c9);
    }

    .btn {
      margin: pxTo(12) auto 0 auto;
      padding: pxTo(1);
      width: pxTo(163);
      border-radius: pxTo(7);
      background-image: $bg1;
      overflow: hidden;

      > div {
        height: pxTo(34);
        background-color: $c2;
        border-radius: pxTo(6);
        text-align: center;

        @extend %ff-rb;
        @include fs-l-w-c(11, 35, 900, $c5);
      }
    }

    .progress {
      margin: pxTo(6) auto 0 auto;
      width: pxTo(162);
      height: pxTo(16);
      border-radius: pxTo(8);
      background-color: $c14;
      overflow: hidden;
      position: relative;

      .per {
        width: 0%;
        height: 100%;
        background-image: $bg1;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 500ms;
      }

      .info {
        height: pxTo(9);

        @extend %v-h-center;
        @extend %no-break;
        @extend %ff-rb;
        @include fs-l-w-c(8, 10, 700, $c2);

        &.success {
          padding-left: pxTo(6+5);

          @include bg($img-base, 'ic_flame.svg');
          background-size: pxTo(6) auto;
          background-position: left center;
        }
      }
    }
  }*/
</style>