<template>
  <div class="details">
    <nav-bar show-back="1" show-my="1">
      <round-v></round-v>
    </nav-bar>

    <div class="banner-box" :style="{height: category !== 36 ? '24rem' : '13.3rem'}">
      <template v-if="info">
        <banner :info="info" :total="successGroups" :daily-status="dailyStatus"></banner>
      </template>
      <!-- 日推售磬 -->
      <div class="mask" v-if="dailyStatus != 1"></div>
    </div>

    <!--round type="white"></round-->

    <!-- 活动说明 -->
    <groups-intro :num="groupNum"></groups-intro>

    <!-- 当前拼团(显示1个拼团中+1个拼团成功) -->
    <template v-if="(isDaily && dailyStatus == 0) || !isDaily">
      <div class="groups-join box" v-if="currentGroups && currentGroups.length > 0">
        <div class="title fx-row fx-center">
          <div class="fx-1">JOIN untuk dapatkan kupon</div>
          <div class="more" @click="setShowAllGroups(true)">Lihat lebih></div>
        </div>

        <div class="list">
          <group-item v-for="(item) of currentGroups" :info="item" :key="item.id"
                      @one="setShowJoinGroup" @all="setShowAllGroups" @over="freshGroups"></group-item>
        </div>
      </div>
    </template>

    <!-- 商品评论显示前2条 -->
    <div class="comments box">
      <div class="title fx-row fx-center">
        <div class="fx-1">Evaluasi toko ({{commentsTotal}})</div>
        <div class="more" @click="gotoComments">Lihat lebih></div>
      </div>

      <!--<div> class="tags">
        <tags :list="commentsTags"></tags>
      </div>-->

      <div class="list">
        <comment-item v-for="(item, index) of comments" :key="index" :info="item"></comment-item>
      </div>

      <!--div class="btn b-t" @click="gotoComments">LIHAT LEBIH BANYAK(100K+)></div-->
    </div>

    <readme :details="details"></readme>

    <!-- 推荐商品 -->
    <recommend-goods></recommend-goods>

    <div class="iphone-x"></div>

    <!-- 提示用户参团通知 -->
    <template v-if="(isDaily && dailyStatus == 0) || !isDaily">
      <join-notify :list="top3Groups" @all="setShowAllGroups"></join-notify>
    </template>

    <!-- 底部按钮 -->
    <template v-if="info">
      <bottom :activityId="activityId" :isDaily="isDaily" :dailyStatus="dailyStatus"
              :dailyRemain="dailyRemain" @fresh="fresh"></bottom>
    </template>

    <!-- 弹窗 -->
    <popup-join-group :is-show="isShowJoinGroup" :info="oneGroup"
                      @close="setShowJoinGroup" @over="freshGroups"></popup-join-group>
    <popup-all-groups :is-show="isShowAllGroups" :list="unfinishGroups"
                      @close="setShowAllGroups" @over="freshGroups"></popup-all-groups>
  </div>
</template>

<script>
  //component
  import NavBar from '../../components/navBar'
  import RoundV from '../../components/roundV'
  import Banner from './components/banner'
  import GroupsIntro from '../../components/groupsIntro'
  import GroupItem from './components/groupItem'
  import CommentItem from '../../components/commentItem'
  import JoinNotify from './components/joinNotify'
  import Readme from './components/readme'
  import RecommendGoods from '../../components/recommendGoods'
  import Bottom from './components/bottom'
  //弹窗
  import PopupJoinGroup from '../../components/popupJoinGroup'
  import PopupAllGroups from '../../components/popupAllGroups'
  //api
  import service from '../../service'

  let lock = false
  let activityId = 1

  export default {
    data() {
      return {
        info: false,
        userInfo: false,
        unfinishGroups: false,
        finishedGroups: false,
        successGroups: 0,
        comments: [],
        commentsTotal: 0,
        isShowJoinGroup: false,
        isShowAllGroups: false
      }
    },
    components: {
      NavBar,
      RoundV,
      Banner,
      GroupsIntro,
      GroupItem,
      CommentItem,
      JoinNotify,
      Readme,
      RecommendGoods,
      Bottom,
      PopupJoinGroup,
      PopupAllGroups
    },
    computed: {
      activityId() {
        return this.info && this.info.id || activityId
      },
      //成团人数
      groupNum() {
        return this.info && this.info.group_members || 0
      },
      //显示一个进行中一个已成功拼团
      currentGroups() {
        let groups = []

        //存在已完成拼团
        if (this.unfinishGroups && this.unfinishGroups.length > 0 &&
          this.finishedGroups && this.finishedGroups.length > 0) {
          groups.push(this.unfinishGroups[0])
          groups.push(this.finishedGroups[0])
        } else if (this.unfinishGroups && this.unfinishGroups.length > 0) {
          groups = this.unfinishGroups.slice(0, 2)
        }

        return groups
      },
      //第一个进行中的拼团
      oneGroup() {
        return this.unfinishGroups && this.unfinishGroups[0] || false
      },
      //前三个进行中的拼团
      top3Groups() {
        return this.unfinishGroups && this.unfinishGroups.slice(0, 3) || []
      },
      details() {
        return this.info && this.info.goods_info || ''
      },
      category(){
        return this.info && this.info.category
      },
      //是否日推
      isDaily() {
        return this.info && this.info.category == 4 || false
      },
      //日推活动状态0进行中 -1未开始 1售完
      dailyStatus() {
        let status = -1

        if (this.isDaily) {
          //倒计时状态
          if (this.dailyRemain >= 0) {
            status = -1
          }
          //进行中
          else if (this.dailyRemain < 0 && this.info.status == 0) {
            status = 0
          }
          //售磬
          else {
            status = 1
          }
        }

        return status
      },
      //日推活动倒计时
      dailyRemain() {
        let remain = -1

        if (this.isDaily) {
          let current = this.info.current_time
          let start = this.info.start_time
          remain = start - current
        }

        return remain
      }
    },
    methods: {
      init() {
        Promise.all([
          this.getActivityById(),
          this.getGroups(),
          this.getComments()
        ]).then(this.$hideLoading)
      },
      fresh() {
        this.$showLoading()
        this.init()
      },
      freshGroups() {
        if (lock) {
          return
        }
        lock = true

        this.getGroups().then(() => {
          lock = false
          this.$hideLoading()
        })
      },
      //获取活动详情
      getActivityById() {
        return service.getActivityById(activityId).then((data) => {
          this.info = data
        }).catch(this.$apiErrorHandler)
      },
      //获取拼团中前5个/拼团成功前1个
      getGroups() {
        return Promise.all([this.getGroupsByActivityId(0), this.getGroupsByActivityId(1, 1)])
          .then(([unfinish, finished]) => {
            this.unfinishGroups = unfinish
            this.finishedGroups = finished
          })
      },
      getGroupsByActivityId(status, page_size = 5) {
        return service.getGroupsByActivityId({status, page_size, activityId: activityId}).then((data) => {
          //拼团成功总数
          if (status == 1 && data && data.total) {
            this.successGroups = data && data.total
          }

          return data && data.groups || []
        }).catch(() => {
          return []
        })
      },
      getComments() {
        return service.getComments(this.activityId,{page_size: 2}).then((data) => {
          this.commentsTotal = data.total
          this.comments = data && data.comments || []
        }).catch(() => {
          this.comments = []
        })
      },
      gotoComments() {
        // this.gotoPage('comments',{activityId})
        //    this.$router.push({
        //   path: `/groups/comments/${activityId}`,
        // })
        this.$router.push({
          path: '/groups/comments',
          query: {
            goodid: activityId
          }
        })
      },
      setShowJoinGroup(isShow) {
        this.isShowJoinGroup = isShow
      },
      setShowAllGroups(isShow) {
        this.isShowAllGroups = isShow
      }
    },
    created() {
      activityId = this.$route.query.id || 1
      //添加登录成功监听
      this.$bus.$off('loginSuccess', this.freshGroups)
      this.$bus.$on('loginSuccess', this.freshGroups)

      this.$showLoading()

      this.init()

      window.track('wowsaa-groups', 'details', 'expose')
    },
    beforeDestroy() {
      this.$bus.$off('loginSuccess', this.freshGroups)
      this.isShowJoinGroup = false
      this.isShowAllGroups = false
    },
    // 解决同页面不同query不刷新请求的问题
    watch: {
      '$route' () {
          this.$router.go(0);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .details {
    padding-bottom: pxTo(94);

    @extend %page;
    background-color: $c11;
  }

  .banner-box {
    width: 100%;
    // height: pxTo(294-28);
    background-color: $c1;
    position: relative;
  }

  .groups-join {
    padding-bottom: pxTo(10);
  }
</style>