<template>
  <div class="readme box">
    <div class="title fx-row fx-center b-b">
      <div class="fx-1">Informasi Komoditas</div>
    </div>
    <div class="goods-details" v-html="details">
    </div>
  </div>
</template>

<script>
  export default {
    props: ['details']
  }
</script>