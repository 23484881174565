<template>
  <div class="bottom iphone-x b-t">
    <!-- 每日活动进行中或其他活动 -->
    <div class="fx-row" v-if="(isDaily && dailyStatus == 0) || !isDaily">
      <!-- 免费获取,未发起拼团显示 -->
      <div class="btn group fx-1 fx-center" @click="onStartNewGroup" v-if="hasJoin === false">
        <div class="icon"></div>
        <div class="txt m-l-8">DAPATKAN SECARA GRATIS</div>
      </div>

      <!-- 参加过拼团显示 -->
      <template v-if="hasJoin">
        <div class="btn fx-1 fx-center" @click="gotoMy">
          <div class="txt">GRUP SAYA</div>
        </div>
        <div class="btn group fx-1 fx-center" @click="onStartNewGroup">
          <div class="txt">TERIMA TAGIHAN TELEPON</div>
        </div>
      </template>
    </div>

    <!-- 每日活动倒计时中 -->
    <div v-else-if="isDaily && dailyStatus == -1">
      <div class="daily-count-down iphone-x">
        <div class="fx-row fx-center">
          <div class="txt">PEMBELIAN SETELAH</div>
          <times type="min-gray" :remain="dailyRemain" @over="onOver"></times>
        </div>
      </div>

      <!-- 通知 -->
      <div class="btn notify fx-center" @click="dailySubscribe(activityId)">
        <div class="icon"></div>
        <div class="txt">INGATKAN SAYA</div>
      </div>
    </div>

    <!-- 每日活动售磬 -->
    <div class="fx-row" v-if="isDaily && dailyStatus == 1">
      <div class="btn fx-1 fx-center" @click="gotoMy">
        <div class="txt">UPDATE BESOK JAM 20:00</div>
      </div>
      <div class="btn notify fx-1 fx-center" @click="dailySubscribe(activityId)">
        <div class="txt">INGATKAN SAYA</div>
      </div>
    </div>
  </div>
</template>

<script>
  import groupHandler from '../../../mixins/groupHandler'
  //component
  import Times from '../../../components/times'

  let lock = false

  export default {
    props: ['activityId', 'isDaily', 'dailyStatus', 'dailyRemain'],
    data() {
      return {
        hasJoin: null
      }
    },
    mixins: [groupHandler],
    components: {
      Times
    },
    methods: {
      init() {
        this.$getAuth().then(() => {
          return this.getUserGroupStatus(this.activityId)
            .then(({create_group_num, join_group_num}) => {
              if (create_group_num + join_group_num <= 0) {
                this.hasJoin = false
              } else {
                this.hasJoin = true
              }
            })
        }).catch(() => {
          this.hasJoin = false
        })
      },
      gotoMy() {
        return this.$getAuth().then(() => {
          //已登录
          return this.gotoPage('my')
        }).catch(() => {
          //未登录
          this.$bus.$emit('login', {
            callback: () => {
              this.gotoPage('my')
            }
          })
        })
      },
      onStartNewGroup() {
        if (lock) {
          return
        }
        lock = true

        this.startNewGroup(this.activityId).then(() => {
          lock = false
        })
      },
      onOver() {
        this.$emit('fresh')
      }
    },
    created() {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
  .bottom {
    width: 100%;
    background-color: $c2;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;

    .btn {
      height: pxTo(48);
      position: relative;

      .icon {
        width: pxTo(24);
        height: pxTo(24);
      }

      .txt {
        text-align: center;

        @extend %ff-rb;
        @include fs-l-w-c(13, 13, 800, $c5);

        &.m-l-8 {
          margin-left: pxTo(8);
        }
      }

      &.group {
        background-image: $bg1;

        .icon {
          @include bg($img-base, 'ic_flame.svg');
          background-size: auto 100%;
        }

        .txt {
          color: $c2;
        }
      }

      &.notify {
        background-image: $bg1;

        .icon {
          margin-right: pxTo(3);

          @include bg($img-base, 'notify.png');
          background-size: auto 100%;
        }

        .txt {
          color: $c2;
        }
      }
    }

    .daily-count-down {
      width: 100%;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.10);
      background-color: $c2;

      > div {
        height: pxTo(36);

        .txt {
          text-align: center;
          margin-right: pxTo(8);

          @extend %ff-rb;
          @include fs-l-w-c(13, 13, 800, $c5);

          &.m-l-8 {
            margin-left: pxTo(8);
          }
        }
      }
    }
  }
</style>